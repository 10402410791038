<template>
  <div>
    <MainHero />

    <CTASignup />

    <div class="mt-12 flex flex-col">
      <h1 class="text-gray-200 mx-auto">
        Meet the Team
      </h1>
      <BioCards />
    </div>
  </div>
</template>

<script setup>

</script>